export default {
    data: () => ({
        userData: JSON.parse(localStorage.getItem('userData')),
    }),
    methods: {
        isAdmin() {
            if ([1, 2].includes(this.userData.id)) return true;
            return false;
        }
    }
}
