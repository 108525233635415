<template>
  <section>
    <StockComfirmSearch></StockComfirmSearch>
    <StockComfirmList></StockComfirmList>
  </section>
</template>

<script>
import StockComfirmSearch from "@/views/stock/confirm/StockComfirmSearch";
import StockComfirmList from "@/views/stock/confirm/StockComfirmList";

export default {
  name: "StockComfirmHome",
  components: {StockComfirmSearch, StockComfirmList}
}
</script>

<style scoped>

</style>
