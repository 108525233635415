<template>
  <section>
    <div class="row">
      <div class="col-md-12">
        <b-overlay
            :show="show"
            rounded="sm"
        >
          <div class="card">
            <div class="card-body">
              <b-form @submit.prevent>
                <div class="row">
                  <b-col md="3">
                    <b-row>
                      <b-col md="6" class="m-0 p-0">
                        <b-form-input type="text" size="sm" v-model="_search.product.id" placeholder="Ürün No"/>
                        <b-form-input type="text" size="sm" v-model="_search.product.barcode" placeholder="Barkod"/>
                      </b-col>
                      <b-col md="6" class="m-0 p-0">
                        <b-form-input type="text" size="sm" v-model="_search.product.name" placeholder="Ürün Adı"/>
                        <b-form-input type="text" size="sm" v-model="_search.shelve_name" placeholder="Raf Adı"/>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="8">
                    <b-row>
                      <b-col md="2" class="m-0 p-0">
                        <v-select
                            v-model="_search.suppliers"
                            label="name"
                            multiple
                            :options="_suppliers"
                            :reduce="item => item.id"
                            class="select-size-sm"
                            placeholder="Tedarikçi"
                            :closeOnSelect="false"
                        >
                        </v-select>
                      </b-col>
                      <b-col md="2" class="m-0 p-0">
                        <v-select
                            v-model="_search.status"
                            label="status"
                            multiple
                            :options="_matchStatus"
                            :reduce="statu => statu.id"
                            class="select-size-sm"
                            placeholder="Durum"
                        />
                      </b-col>
                      <b-col md="2" class="m-0 p-0">
                        <v-select
                            v-model="_search.stock_return"
                            label="name"
                            :options="stock_return"
                            :reduce="item => item.id"
                            class="select-size-sm"
                            placeholder="İade/Stok"
                        />
                      </b-col>
                      <b-col md="2" class="m-0 p-0">
                        <v-select
                            v-model="_search.sorting"
                            label="key"
                            :options="sortingList"
                            :reduce="item => item.val"
                            class="select-size-sm"
                            placeholder="Sıralama"
                        />
                        <v-select
                            v-model="_search.orderBy"
                            label="key"
                            :options="orderByList"
                            :reduce="item => item.val"
                            class="select-size-sm"
                            placeholder="Sır. Tarihi"
                        />
                      </b-col>
                      <b-col md="2" class="m-0 p-0">
                        <v-select
                            v-model="_search.add_user_id"
                            label="name"
                            multiple
                            :options="_users"
                            :reduce="item => item.id"
                            class="select-size-sm"
                            placeholder="Ekleyen"
                        />

                        <v-select
                            v-model="_search.approver_user_id"
                            label="name"
                            multiple
                            :options="_users"
                            :reduce="item => item.id"
                            class="select-size-sm"
                            placeholder="Onaylayan"
                        />

                      </b-col>
                      <b-col md="2" class="m-0 p-0">
                        <v-select
                            v-model="_search.limit"
                            :options="[1,5,10,20,50,100,250,500,1000,2500,10000]"
                            class="select-size-sm"
                            placeholer="Limit"
                        />
                      </b-col>

                    </b-row>
                  </b-col>
                  <div class="col-1">
                    <b-button-group size="sm">
                      <b-button variant="primary" @click="getAll(1)" type="submit">Getir</b-button>
                    </b-button-group>
                  </div>
                </div>
              </b-form>
              <div class="col-md-12">
                <b-row>
                  <Pagination :pagination="_pagination" @pageClick="getAll"/>
                </b-row>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>

    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import vSelect from "vue-select";
import Pagination from "@/components/Pagination";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  name: "StockComfirmSearch",
  components: {vSelect, Pagination},
  data: () => ({
    show: false,
    stock_return: [{id: 9, name: 'Stok'}, {id: 27, name: 'İade'}],
    sortingList: [{key: 'A-Z', val: 'ASC'},
      {key: 'Z-A', val: 'DESC'},],
    orderByList: [
      {key: 'Ürün ID', val: 'product_id'},
      {key: 'Raf Adı', val: 'shelve_name'},
      {key: 'Adet', val: 'quantity'},
      {key: 'Fiyat', val: 'price'},
      {key: 'Ekleme Tarihi', val: 'created_at'},
      {key: 'Güncelleme Tarihi', val: 'updated_at'},
    ],
  }),
  computed: {
    ...mapGetters('matchStatus', ["_matchStatus"]),
    ...mapGetters('stockComfirm', ["_search", "_pagination"]),
    ...mapGetters('users', ['_users']),
    ...mapGetters("supplier", ["_suppliers"]),
  },
  methods: {
    getAll(page) {
      this.show = true;
      this._search.page = page;
      this.$store.dispatch('stockComfirm/getAll')
          .then(res => {
            this.show = false;
            this.$toast({
              component: ToastificationContent,
              props: res
            })
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
